import React, { useState } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button, TextField } from "@material-ui/core"
import { API_ROOT, ENV } from "gatsby-env-variables"
import axios from "axios"
import CircularProgress from "@material-ui/core/CircularProgress"
import LOGO from "../images/invoke_logo3.png"
import { Snackbar as MaterialSnackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { Link } from "gatsby"

const ForgetPassword = () => {
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [message, setMessage] = useState("")
  const [severity, setSeverity] = useState("success")
  const onSubmit = e => {
    e.preventDefault()
    if (email.trim() === "") {
      setError(true)
      setSeverity("error")
      setMessage("Please enter your email!")
    } else {
      setLoading(true)
      axios
        .post(`${API_ROOT}${ENV}/external-user/forgot-password`, {
          email: email,
        })
        .then(response => {
          if (response.data.statusCode === 200) {
            setLoading(false)
            setError(true)
            setSeverity("success")
            setMessage("Please check your email for instruction!")
          } else {
            setLoading(false)
            setError(true)
            setSeverity("error")
            setMessage(response.data.devMessage)
          }
        })
    }
  }
  const onClickCloseSnackbar = () => {
    setError(false)
  }
  return (
    <Layout login>
      <Seo title="Reset Password - INVOKE by FREE THE WORK" />
      <div className="bg login-page">
        <div className="container">
          <div className="top">
            <Link to="/">
              <img src={LOGO} alt="INVOKE" />
            </Link>
            <p>Forgot password</p>
          </div>
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-wrapper" style={{marginTop: '30px'}}>
            <div className="flex">
              <TextField
                type="email"
                fullWidth
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
              />
            </div>
            <div className="flex actions reset">
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                className="reset"
              >
                {loading ? (
                  <CircularProgress size="24px" color="white" />
                ) : (
                  "Reset Password"
                )}
              </Button>
            </div>
          </div>
        </form>
      </div>

      <MaterialSnackbar
        open={error}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={3000}
        onClose={onClickCloseSnackbar}
        disableWindowBlurListener
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={onClickCloseSnackbar}
          severity={severity}
        >
          {message}
        </MuiAlert>
      </MaterialSnackbar>
    </Layout>
  )
}

export default ForgetPassword
